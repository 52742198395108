import React, {ReactNode} from 'react';
import {AddressDropdown} from './AddressDropdown/AddressDropdown';
import {MemberDetailsSummary} from './MemberDetailsSummary/MemberDetailsSummary';
import {classes} from './MemberDetails.st.css';
import {ADD_NEW_ADDRESS_ID} from '../constants';
import {MemberAddressNotValidError} from './MemberAddressNotValidError/MemberAddressNotValidError';
import {useMemberDetailsData} from './WithMemberDetailsData';
import {CustomFieldModel} from '../../../domain/models/CustomField.model';

export enum MemberDetailsDataHook {
  root = 'MemberDetails.root',
  memberForm = 'MemberDetails.memberForm',
}

export interface MemberDetailsProps {
  Form: () => ReactNode;
  dropdownLabel: string;
  customField?: CustomFieldModel;
}

export const MemberDetails = ({Form, dropdownLabel, customField}: MemberDetailsProps) => {
  const {areMemberDetailsValid, selectedAddressesServiceId, editMode} = useMemberDetailsData();

  return (
    <div>
      <div data-hook={MemberDetailsDataHook.root} className={classes.root}>
        <AddressDropdown dropdownLabel={dropdownLabel} />
        {selectedAddressesServiceId !== ADD_NEW_ADDRESS_ID && !editMode && (
          <>
            <MemberDetailsSummary customField={customField} />
            {!areMemberDetailsValid && <MemberAddressNotValidError />}
          </>
        )}
        {(editMode || selectedAddressesServiceId === ADD_NEW_ADDRESS_ID) && (
          <div data-hook={MemberDetailsDataHook.memberForm}>{Form()}</div>
        )}
      </div>
    </div>
  );
};

import {classes} from './DetailsFormButtons.st.css';
import {Button, ButtonPriority, ButtonSize, StatesButtonStates} from 'wix-ui-tpa';
import {NextStepButton} from '../NextStepButton/NextStepButton';
import React from 'react';
import {useMemberDetailsData} from '../../../../MemberDetails/WithMemberDetailsData';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {ADD_NEW_ADDRESS_ID} from '../../../../constants';
import {useControllerProps} from '../../../../Widget/ControllerContext';

export enum FormDetailsButtonsDataHook {
  continueButton = 'FormDetailsButtons.continue',
  cancelButton = 'FormDetailsButtons.cancel',
}

export const DetailsFormButtons = ({
  onContinue,
  continueButtonDisabled,
  continueButtonState,
}: {
  onContinue: () => void;
  continueButtonDisabled: boolean;
  continueButtonState: StatesButtonStates;
}) => {
  const localeKeys = useLocaleKeys();

  const {
    memberStore: {addressesInfo, isMember, isAddressesAppInstalled, defaultAddressId},
  } = useControllerProps();

  const {setEditMode, editMode, selectedAddressesServiceId, resetMemberDetailsState, setAddressServiceById} =
    useMemberDetailsData();
  const onCancel = () => {
    if (editMode) {
      setEditMode(false);
    } else if (selectedAddressesServiceId === ADD_NEW_ADDRESS_ID) {
      resetMemberDetailsState();
    } else {
      setEditMode(false);
      setAddressServiceById(defaultAddressId!);
    }
  };

  const isMemberWithoutAddressServiceId = isMember && isAddressesAppInstalled && !selectedAddressesServiceId;
  const shouldShowCancelButton =
    (isMemberWithoutAddressServiceId || editMode || selectedAddressesServiceId === ADD_NEW_ADDRESS_ID) &&
    addressesInfo.addresses.length > 0;

  return (
    <div className={classes.buttonsWrapper}>
      {shouldShowCancelButton && (
        <Button
          data-hook={FormDetailsButtonsDataHook.cancelButton}
          priority={ButtonPriority.basicSecondary}
          size={ButtonSize.medium}
          className={classes.cancelButton}
          onClick={onCancel}
          upgrade>
          {isMemberWithoutAddressServiceId
            ? localeKeys.checkout.chooseNewAddress.cta()
            : localeKeys.checkout.cancel.cta()}
        </Button>
      )}
      <NextStepButton
        onClick={onContinue}
        text={editMode ? localeKeys.checkout.saveAndContinue.cta() : localeKeys.checkout.continue_button_label()}
        dataHook={FormDetailsButtonsDataHook.continueButton}
        disabled={continueButtonDisabled}
        buttonState={continueButtonState}
      />
    </div>
  );
};
